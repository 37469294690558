import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import UsersGrid from "../components/UsersGrid";
import PhoneGrid from "../components/PhoneGrid";
import DriversGrid from "../components/DriversGrid";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    maxHeight: 350,
    marginLeft: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <Box p={0}>{children}</Box>}</div>;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function UsersLayout() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [panelIdx, setPanelIdx] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setPanelIdx(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <AppBar position="static" color="default">
            <Tabs value={panelIdx} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
              <Tab label={t("users.tabusers")} />
              <Tab label={t("users.tabmobiles")} />
              <Tab label={t("users.tabdrivers")} />
            </Tabs>
          </AppBar>
          <TabPanel value={panelIdx} index={0}>
            <UsersGrid />
          </TabPanel>
          <TabPanel value={panelIdx} index={1}>
            <PhoneGrid />
          </TabPanel>
          <TabPanel value={panelIdx} index={2}>
            <DriversGrid />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
}
