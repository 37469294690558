import React, { useRef } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import ApplicationMap from "../components/ApplicationMap";
import MainGrid from "../components/Maingrid";
import EventsGrid from "../components/EventsGrid";

export default function Tracking() {
  const mapRef = useRef();

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} lg={7} xl={8}>
        <ApplicationMap mapref={mapRef} />
      </Grid>
      <Grid item xs={12} sm={12} lg={5} xl={4}>
        <Paper>
          <EventsGrid mapref={mapRef} />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <MainGrid mapref={mapRef} />
      </Grid>
    </Grid>
  );
}
