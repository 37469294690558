import React, { Component } from "react";

import { Map, Marker, Popup, TileLayer, Polygon, Tooltip } from "react-leaflet";
import L from "leaflet";
import Leaflet from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

import agroApi from "../services/agroApi";
import { UserContext } from "../store/UserContext";

Leaflet.Icon.Default.imagePath = "//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/";

export const tractorIcon = new L.Icon({
  iconUrl: "/images/tractor.svg",
  iconRetinaUrl: "/images/tractor.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [30, 55],
});

export const icon_1 = new L.Icon({
  iconUrl: "/images/1.svg",
  iconRetinaUrl: "/images/1.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [90, 100],
});

export const icon_2 = new L.Icon({
  iconUrl: "/images/2.svg",
  iconRetinaUrl: "/images/2.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [45, 90],
});

export const icon_3 = new L.Icon({
  iconUrl: "/images/3.svg",
  iconRetinaUrl: "/images/3.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [45, 90],
});

export const icon_4 = new L.Icon({
  iconUrl: "/images/4.svg",
  iconRetinaUrl: "/images/4.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [45, 90],
});

export const icon_5 = new L.Icon({
  iconUrl: "/images/5.svg",
  iconRetinaUrl: "/images/5.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [30, 55],
});

export const icon_6 = new L.Icon({
  iconUrl: "/images/6.svg",
  //iconRetinaUrl: "/images/6.svg",
  //iconAnchor: [5, 55],
  //popupAnchor: [10, -44],
  iconSize: [25, 45],
});

export const icon_7 = new L.Icon({
  iconUrl: "/images/7.svg",
  iconRetinaUrl: "/images/7.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [45, 90],
});

export const icon_8 = new L.Icon({
  iconUrl: "/images/8.svg",
  iconRetinaUrl: "/images/8.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [45, 90],
});

export const icon_9 = new L.Icon({
  iconUrl: "/images/9.svg",
  iconRetinaUrl: "/images/9.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [45, 90],
});

export const icon_10 = new L.Icon({
  iconUrl: "/images/10.svg",
  //iconRetinaUrl: "/images/10.svg",
  //iconAnchor: [5, 55],
  //popupAnchor: [10, -44],
  iconSize: [45, 90],
});

export const icon_11 = new L.Icon({
  iconUrl: "/images/11.svg",
  iconRetinaUrl: "/images/11.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [45, 90],
});

export const icon_12 = new L.Icon({
  iconUrl: "/images/12.svg",
  iconRetinaUrl: "/images/12.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [35, 70],
});

export const icon_15 = new L.Icon({
  iconUrl: "/images/15.svg",
  iconRetinaUrl: "/images/6.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [30, 45],
});

export default class ApplicationMap extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.loadGeoJSON = this.loadGeoJSON.bind(this);
    this.loadPumps = this.loadPumps.bind(this);
    this.intervalID = 0;
    this.customer_id = 0;
    this.state = {
      markers: [],
      geojson: [],
      pumps: [],
    };
  }

  async loadData() {
    //console.log("ApplicationMap interval");

    const User = this.context.User;

    try {
      var data = await agroApi.get("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/maingrid/cblatlondata", {
        headers: { token: User.token },
      });
      //console.log(data);

      var { markers } = this.state;
      markers = [];
      data.data.forEach((element) => {
        markers.push({
          idx: element.device_id,
          txt: element.device_name,
          type: element.devicetype_id,
          position: [element.latitude, element.longitude],
        });
      });
      //console.log(markers);
      this.setState({ markers });
    } catch (e) {
      //console.log("ERROROROROROR " + e);
    }
  }

  async loadGeoJSON() {
    const User = this.context.User;

    var { geojson } = this.state;
    geojson = [];

    try {
      var data = await agroApi.get("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/geojsonn", {
        headers: { token: User.token },
      });
      geojson = data.data;
      this.setState({ geojson });
    } catch (e) {
      //console.log("ERROROROROROR " + e);
    }
  }

  async loadPumps() {
    const User = this.context.User;

    try {
      var data = await agroApi.get("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/maingrid/pumps", {
        headers: { token: User.token },
      });

      console.log("data = ", data);
      var { pumps } = this.state;
      pumps = [];
      data.data.forEach((element) => {
        pumps.push({
          idx: element.device_id,
          txt: element.device_name,
          type: element.devicetype_id,
          position: [element.device_latitude, element.device_longitude],
        });
      });
      console.log("pumps = ", pumps);
      this.setState({ pumps });
    } catch (e) {
      //console.log("ERROROROROROR " + e);
    }
  }

  componentDidMount() {
    this.loadGeoJSON();
    this.loadData();
    this.loadPumps();
    this.intervalID = setInterval(this.loadData, 60000);
    this.customer_id = this.context.User.ActiveCustomer;
  }

  componentDidUpdate() {
    if (this.customer_id !== this.context.User.ActiveCustomer) {
      this.customer_id = this.context.User.ActiveCustomer;
      this.loadGeoJSON();
      this.loadData();
      this.loadPumps();
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  locateDevice = (device_id) => {};

  render() {
    const User = this.context.User;

    return (
      <div id="mapid">
        <Map style={{ height: "43vh", width: "10hv" }} center={[User.customers[User.ActiveCustomer].customer_lat, User.customers[User.ActiveCustomer].customer_lon]} zoom={10} ref={this.props.mapref} id="mapid" maxZoom={20} enableHighAccuracy>
          <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
          <Marker position={[User.customers[User.ActiveCustomer].customer_lat, User.customers[User.ActiveCustomer].customer_lon]} color="#fff">
            <Popup>{User.customers[User.ActiveCustomer].customer_name}</Popup>
          </Marker>
          {this.state.pumps.map((e) => (
            <Marker key={`pump-${e.idx}`} position={e.position} Title="bbb" icon={e.type === "6" ? icon_6 : icon_15}>
              <Popup>
                <span>{e.txt}</span>
              </Popup>
              <Tooltip sticky="true" opacity="0.7" className="fences">
                {e.txt}
              </Tooltip>
            </Marker>
          ))}
          <MarkerClusterGroup disableClusteringAtZoom={16} removeOutsideVisibleBounds={true}>
            {this.state.markers.map((e) => (
              <Marker
                key={`marker-${e.idx}`}
                position={e.position}
                Title="aaa"
                icon={
                  e.type === "1"
                    ? icon_1
                    : e.type === "2"
                    ? icon_2
                    : e.type === "3"
                    ? icon_3
                    : e.type === "4"
                    ? icon_4
                    : e.type === "5"
                    ? icon_5
                    : e.type === "6"
                    ? icon_6
                    : e.type === "7"
                    ? icon_7
                    : e.type === "8"
                    ? icon_8
                    : e.type === "9"
                    ? icon_9
                    : e.type === "10"
                    ? icon_10
                    : e.type === "11"
                    ? icon_10
                    : e.type === "14"
                    ? icon_4
                    : icon_12
                }
              >
                <Popup>
                  <span>{e.txt}</span>
                </Popup>
                <Tooltip sticky="true" opacity="0.7" className="fences">
                  {e.txt}
                </Tooltip>
              </Marker>
            ))}
          </MarkerClusterGroup>
          {this.state.geojson.map((p) => (
            <Polygon key={`poly-${p.fence_id}`} positions={p.fence_vertex} color={p.crop_color}>
              <Popup>{p.fence_name + " " + p.crop_name}</Popup>
              <Tooltip sticky="true" opacity="0.7" className="fences">
                {p.fence_name + " " + p.crop_name}
              </Tooltip>
            </Polygon>
          ))}
        </Map>
      </div>
    );
  }
}
