import React from "react";
import Sound from "react-sound";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import WarningIcon from "@material-ui/icons/Warning";
import PlaylistAddOutlinedIcon from "@material-ui/icons/PlaylistAddOutlined";

import { red, blue, amber } from "@material-ui/core/colors";

import { UserContext } from "../store/UserContext";
import agroApi from "../services/agroApi";

var moment = require("moment");

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "43vh",
    overflow: "scroll",
  },
});

class EventsGrid extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.saveEventStatus = this.saveEventStatus.bind(this);
    this.intervalID = 0;
    this.customer_id = 0;
    this.state = {
      events: [],
      playStatus: Sound.status.STOPPED,
      playFile: "/sounds/AlertaTelemetria.mp3",
    };
  }

  async saveEventStatus(event_id, event_status) {
    const User = this.context.User;
    await agroApi.put(
      "/customers/" +
        User.customers[User.ActiveCustomer].customer_id +
        "/events/"+event_id, 
      { "event_status": event_status, "user_id": User.user_id },
      { headers: { token: User.token } }
    );
    this.setState({ playStatus: Sound.status.STOPPED });
    await this.loadData();
  }

  async loadData() {
    //console.log("Events interval");

    const User = this.context.User;
    try {
      var data = await agroApi.get(
        "/customers/" +
          User.customers[User.ActiveCustomer].customer_id +
          "/events",
        {
          headers: { token: User.token },
        }
      );
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].eventtype_sound > 0) {
          this.setState({ playStatus: Sound.status.PLAYING })
          break;
        }
      }
      var { events } = this.state;
      events = data.data;
      User.alarms = data.data.lentgth;
      this.setState({ events });
    } catch (e) {
      //console.log("ERROROROROROR " + e);
    }
  }

  componentDidMount() {
    this.loadData();
    this.intervalID = setInterval(this.loadData, 60000);
    this.customer_id = this.context.User.ActiveCustomer;
  }

  componentDidUpdate() {
    if (this.customer_id !== this.context.User.ActiveCustomer) {
      this.customer_id = this.context.User.ActiveCustomer;
      this.loadData();
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    //const User = this.context.User;
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div style={{ height: "100%", width: "100%hv" }}>
        <List className={classes.root}>
          {this.state.events.map((event, idx) => (
            <ListItem
              key={`event-${event.event_id}`}
              divider={idx !== this.state.events.length - 1}
            >
              <Icon
                style={
                  event.eventtype_severity === 1
                    ? { color: blue[500] }
                    : event.eventtype_severity === 2
                    ? { color: amber[300] }
                    : event.eventtype_severity === 3
                    ? { color: red[300] }
                    : { color: red[800] }
                }
              >
                {event.eventtype_severity === 1 ||
                event.eventtype_severity === 2 ? (
                  <ErrorOutlineOutlinedIcon />
                ) : (
                  <WarningIcon />
                )}
              </Icon>

              <ListItemText
                style={{ marginLeft: 10 }}
                secondary={
                  t(event.eventtype_name) +
                  " (" +
                  event.event_count +
                  ") " +
                  moment(event.event_datetime)
                    .utc(0)
                    .format("YYYY/MM/DD HH:mm")
                }
              >
                {event.device_id + " - " + event.device_name + ", " + event.ruletype_name }
              </ListItemText>
              <IconButton
                onClick={() => {this.saveEventStatus(`${event.event_id}`, 2)}}
             >
                <PlaylistAddOutlinedIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Sound
          url={this.state.playFile}
          playStatus={this.state.playStatus}
          volume={100}
          loop={false}
          playbackRate={1}
          autoLoad={true}
          onFinishedPlaying={() => this.setState({ playStatus: Sound.status.STOPPED })}
        />
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation()(EventsGrid));
