import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import { UserContext } from "../store/UserContext";
import agroApi from "../services/agroApi";
import { useHistory } from "react-router-dom";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import BlockIcon from "@material-ui/icons/Block";
import PersonIcon from "@material-ui/icons/Person";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

var moment = require("moment");

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "40ch",
      marginTop: theme.spacing(1),
    },
    "& .NativeSelect-root": {
      margin: theme.spacing(1),
      width: "30ch",
      marginTop: theme.spacing(1),
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  selectEmpty: {
    marginTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(2),
  },
}));

export default function DriversGrid() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { User } = useContext(UserContext);
  const [rowData, setRowData] = useState([]);
  const [newDriver, setNewDriver] = React.useState("");
  const history = useHistory();
  const [driverId, setDriverId] = useState(0);

  function LoadData() {
    agroApi
      .get("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/drivers/grid", {
        headers: { token: User.token },
      })
      .then((data) => {
        setRowData(data.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          history.push("/");
        }
      });
  }

  function ChangeDriverStatus(driverid, status) {
    agroApi
      .post(
        "/customers/" + User.customers[User.ActiveCustomer].customer_id + "/drivers/status",
        { driver_id: driverid, status: status },
        {
          headers: { token: User.token },
        }
      )
      .catch((error) => {
        if (error.response.status === 401) {
          history.push("/");
        }
      });
  }

  useEffect(() => {
    LoadData();
    setNewDriver("");
  }, [User.ActiveCustomer, User.customers, User.token, history]);

  const onRowSelected = (event) => {
    if (event.node.selected) {
      setDriverId(event.node.data.driver_id);
    }
  };

  const handleSubmit = (event) => {
    if (newDriver != null && newDriver != "") {
      agroApi
        .post("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/drivers", { driver_name: newDriver }, { headers: { token: User.token } })
        .then((resp) => {
          LoadData();
          setNewDriver("");
          setTimeout(() => {
            LoadData();
          }, 1000);
        })
        .catch((err) => {});
    }
  };

  const handleBlockNuttonClick = (event) => {
    if (driverId != 0) {
      ChangeDriverStatus(driverId, 0);
      setNewDriver("");
      setDriverId(0);
      setTimeout(() => {
        LoadData();
      }, 1000);
    }
  };

  const handleReleaseButtonClick = (event) => {
    if (driverId != 0) {
      ChangeDriverStatus(driverId, 1);
      setNewDriver("");
      setDriverId(0);
      setTimeout(() => {
        LoadData();
      }, 1000);
    }
  };

  return (
    <div style={{ height: "80vh", width: "100hv" }}>
      <div className={classes.root} style={{ height: "10vh", width: "100hv" }}>
        <br></br>
        <TextField
          id="standard-basic"
          label="Nome do Operador"
          onChange={(event) => setNewDriver(event.target.value)}
          value={newDriver}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button type="submit" variant="contained" color="secondary" className={classes.button} startIcon={<PersonAddIcon />} onClick={handleSubmit}>
          Cadastrar
        </Button>
      </div>
      <div className="ag-theme-balham" style={{ height: "65vh", width: "100hv" }}>
        <ButtonGroup variant="contained" size="small" aria-label="outlined primary button group">
          <Button startIcon={<BlockIcon />} onClick={(e) => handleBlockNuttonClick(e)}>
            Bloquear
          </Button>
          <Button startIcon={<CheckCircleOutlineIcon />} onClick={(e) => handleReleaseButtonClick(e)}>
            Liberar
          </Button>
        </ButtonGroup>
        <AgGridReact
          //ref="agGrid"
          rowSelection="single"
          animateRows
          rowData={rowData}
          modules={AllCommunityModules}
          //getRowNodeId={this.getRowNodeId}
          onRowSelected={onRowSelected}
          //onGridReady={this.onGridReady}*/
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
          }}
          columnDefs={[
            { headerName: t("driversgrid.id"), field: "driver_id", width: 80, minWidth: 50, maxWidth: 120 },
            { headerName: t("driversgrid.name"), field: "driver_name", width: 240, minWidth: 240, maxWidth: 340 },
            { headerName: t("driversgrid.pass"), field: "driver_pass", width: 100, minWidth: 100, maxWidth: 100 },
            {
              headerName: t("driversgrid.status"),
              field: "driver_status",
              width: 100,
              minWidth: 100,
              maxWidth: 120,
              cellRenderer: (data) => {
                if (data.value == 0) {
                  return "Bloqueado";
                } else {
                  return "Liberado";
                }
              },
            },
            {
              headerName: t("driversgrid.add"),
              field: "driver_add",
              width: 200,
              minWidth: 200,
              maxWidth: 200,
              cellRenderer: (data) => {
                if (data.value != null) return moment(data.value).add(-3).format("HH:mm DD/MM/YYYY");
                else return null;
              },
            },
            {
              headerName: t("driversgrid.lastactivitie"),
              field: "last_datetime",
              width: 200,
              minWidth: 200,
              maxWidth: 200,
              cellRenderer: (data) => {
                if (data.value != null) return moment(data.value).utc(0).format("HH:mm DD/MM/YYYY");
                else return null;
              },
            },
            { headerName: t("driversgrid.lastdescription"), field: "last_description", width: 400, minWidth: 400, maxWidth: 320 },
          ]}
        ></AgGridReact>
      </div>
    </div>
  );
}
