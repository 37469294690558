import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import App from "./App";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import muiTheme from "./muiTheme";

// -> remove the i18n.use(reactI18nextModule) in file ./i18n.js
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <MuiThemeProvider theme={muiTheme}>
      <App />
    </MuiThemeProvider>
  </I18nextProvider>,
  document.getElementById("root")
);

//ReactDOM.render( <App/>, document.getElementById('root'));
