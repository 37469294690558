function FilterColors( colorname ) {
    if (colorname === 'red')
        return "filter: invert(11%) sepia(98%) saturate(5960%) hue-rotate(7deg) brightness(86%) contrast(118%);";
    else if (colorname === 'green')
        return "filter: invert(48%) sepia(7%) saturate(4304%) hue-rotate(73deg) brightness(109%) contrast(76%);";
    else if (colorname === 'lightgray')
        return "filter: invert(80%) sepia(7%) saturate(215%) hue-rotate(153deg) brightness(107%) contrast(91%);";
    else if (colorname === 'darkgray')
        return "filter: invert(31%) sepia(8%) saturate(1201%) hue-rotate(155deg) brightness(101%) contrast(89%);";
}

export default FilterColors;