import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../store/UserContext";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import logo2 from "../../assets/logo4.png";
import FlagptBR from "../../assets/FlagptBR.png";
import FlagesES from "../../assets/FlagesES.png";
import FlagenUS from "../../assets/FlagenUS.png";

import { useTranslation } from "react-i18next";

import agroApi from "../../services/agroApi";
import { Container } from "@material-ui/core";

import version from "../../version";
import ReCAPTCHA from "react-google-recaptcha";

function ForgotPass() {}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.agrothings.net/">
        agroThings
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function AppVersion() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {version}
    </Typography>
  );
}

function ImageRandon() {
  var bigSize = [
    "url(./images/agrothings_contact-1024x683.png)",
    "url(./images/bg1.png)",
    "url(./images/Colheitadeira-1024x768.png)",
    "url(./images/Pivo-fazenda-1024x576.png)",
    "url(./images/Plantando-redes-1-1024x576.png)",
    "url(./images/Telemetria-em-tempo-real-1024x576.png)",
    "url(./images/algodao.png)",
    "url(./images/Grade-1027x768.png)",
  ];
  return bigSize[Math.floor(Math.random() * bigSize.length) + 0];
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: ImageRandon(),
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login({ history, props }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState("");
  const [resetemail, setResetEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [pass, setPass] = React.useState(false);
  const [capval, setCapVal] = React.useState(null);

  const { setUser } = useContext(UserContext);

  // Limpa o token de autenticação
  useEffect(() => {
    localStorage.removeItem("agrotoken");
  }, []);

  function onFlagClick(ln) {
    i18n.changeLanguage(ln);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    await agroApi
      .post("/users/login", { email: email, password: password })
      .then((resp) => {
        localStorage.setItem("agrotoken", resp.data.token);
        resp.data["activeLayout"] = 1;
        resp.data["ActiveCustomer"] = 0;
        resp.data["alarms"] = 0;
        resp.data["lines"] = null;
        setUser(resp.data);
        history.push("/main");
      })
      .catch((err) => {
        setError(true);
        //console.log("error");
      });
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
  };

  const handlePasswordClose = (ev) => {
    const btn = ev.currentTarget.value;

    if (btn && btn === "btnOK") {
      agroApi.post("/users/resetpass", { email: resetemail, token: capval }).catch((err) => {
        setError(true);
      });
    }

    setPass(false);
  };

  const handlePasswordOpen = () => {
    setCapVal(null);
    setPass(true);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Snackbar open={error} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert onClose={handleClose} severity="error">
          {t("login.error")}
        </Alert>
      </Snackbar>
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      <Grid item xs={12} sm={8} md={4} component={Container} elevation={6}>
        <div className={classes.paper}>
          <img src={logo2} alt="MP-D55" />
          <div>
            <img className="login_flag" src={FlagptBR} title="PortuguÊs Brasil" alt="PortuguÊs Brasil" onClick={() => onFlagClick("ptBR")} />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <img className="login_flag" src={FlagesES} title="Espanhol" alt="Espanhol" onClick={() => onFlagClick("esES")} />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <img className="login_flag" src={FlagenUS} title="English" alt="English" onClick={() => onFlagClick("enUS")} />
          </div>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField variant="outlined" margin="normal" required fullWidth id="email" label={t("login.email.placeholder")} name="email" autoFocus onChange={(event) => setEmail(event.target.value)} />
            <TextField variant="outlined" margin="normal" required fullWidth name="password" label={t("login.password.placeholder")} type="password" id="pass" onChange={(event) => setPassword(event.target.value)} />
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              {t("login.button")}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" onClick={handlePasswordOpen}>
                  {t("login.forgot")}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
            <Box mt={1}>
              <AppVersion />
            </Box>
          </form>
          <Dialog open={pass} onClose={handlePasswordClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t("login.resetemail")}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("login.resettxt")}</DialogContentText>
              <TextField autoFocus margin="dense" id="resetemail" label={t("login.typeemail")} type="email" fullWidth onChange={(event) => setResetEmail(event.target.value)} />
              <ReCAPTCHA sitekey="6Lex_SspAAAAAINZ27PtBm6l4XliHV8CaB--pSYr" onChange={(val) => setCapVal(val)} />
            </DialogContent>
            <DialogActions>
              <Button value="btnOK" onClick={handlePasswordClose} color="primary" disabled={!capval}>
                Ok
              </Button>
              <Button value="btnCANCEL" onClick={handlePasswordClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    </Grid>
  );
}
