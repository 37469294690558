import React from "react";
import { withRouter } from 'react-router-dom';

import { AgGridReact } from '@ag-grid-community/react';
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

import { withTranslation } from 'react-i18next'

import agroApi from "../services/agroApi";
import { UserContext } from "../store/UserContext";
import FilterColors from "../assets/FilterColors";

var moment = require('moment');

class PhGrid extends React.Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.getRowNodeId = this.getRowNodeId.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
        this.loadData = this.loadData.bind(this);

        this.Rows = [];
        this.intervalID = 0;
        this.customer_id = 0;
    }

    async loadData() {
        const User = this.context.User;

        const { history } = this.props;
        await agroApi.get('/customers/' + User.customers[User.ActiveCustomer].customer_id + '/maingrid/phdata', {headers:{'token': User.token}}).then((data) => {
            this.gridApi.setRowData(data.data);
        }).catch((error) => {
            if (error.response.status === 401)
            {
                history.push("/");
            }
            //console.log(error.response);
        })
    }
    
    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData, 20000);
    }
    
    componentDidUpdate() {
        if (this.customer_id !== this.context.User.ActiveCustomer) {
        this.customer_id = this.context.User.ActiveCustomer;
        this.loadData();
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }


    getRowNodeId(data) {
        return data.device_id;
    }

    onRowSelected = (event) => {
        //if (event.node.selected)
            //console.log('onRowSelected: ' + event.node.data.device_id);
    };    

    scrambleAndRefreshAll = () => {
        var params = { force: true };
        this.gridApi.refreshCells(params);
      };

      onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
      };

    render () {

        const { t } = this.props;
            
        return (
            <div className="ag-theme-balham" style={ {height: '40vh', width: '100hv'} }>
            <AgGridReact
                ref="agGrid"
                rowSelection="single"
                animateRows
                defaultColDef={{
                    sortable: true,
                    filter: true,
                    resizable: true
                }}           
                columnDefs={[ 
                            {headerName: t("maingrid.id"), field: "device_id", width: 80, minWidth: 50, maxWidth: 120},
                            {headerName: t("maingrid.name"), field: "device_name", width: 200, minWidth: 80, maxWidth: 400},
                            {headerName: t("maingrid.last_transmition"), field: "last_transmition", width: 90, minWidth: 80, maxWidth:150, cellRenderer: (data) => {
                                var element = document.createElement("span");
                                var imageElement = document.createElement("img");
                                imageElement.src = "/images/wifi.svg"
                            
                                // visually indicate if this months value is higher or lower than last months value
                                if (data.value < 81) {
                                    imageElement.setAttribute("style", FilterColors("green")); 
                                    imageElement.title = "Online";
                                } else {
                                    imageElement.setAttribute("style", FilterColors("red")); 
                                    imageElement.title = "Offline";
                                }
                                element.appendChild(imageElement);
                                return element;
                            }},
                            {headerName: t("maingrid.servertimestamp"), field: "servertimestamp", width: 150, minWidth: 80, maxWidth:200, cellRenderer: (data) => {
                                if (data.value != null)
                                    return moment(data.value).utc(0).format('YYYY/MM/DD HH:mm');
                                else
                                    return null;
                            }},
                            {headerName: t("maingrid.hidrometer"), field: "pulse", width: 150, minWidth: 80, maxWidth:200, cellRenderer: (data) => {
                                if (data.value != null)
                                    return data.value;
                                else
                                    return null;
                            }},
                            {headerName: t("maingrid.P0"), field: "p0", width: 115, minWidth: 115, maxWidth: 115, cellRenderer: (data) => {
                                if (data.node.data.device_iodef == null) 
                                    return null;
                                if (Object.keys(data.node.data.device_iodef[0]).length > 0) {
                                    var element = document.createElement("span");

                                    var imageElement = document.createElement("img");
                                    if (data.value === 0)
                                    {
                                        imageElement.src = data.node.data.device_iodef[0].iconon;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[0].coloron)); 
                                        imageElement.title = data.node.data.device_iodef[0].hinton;
                                    }
                                    else {
                                        imageElement.src = data.node.data.device_iodef[0].iconoff;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[0].coloroff));
                                        imageElement.title = data.node.data.device_iodef[0].hintoff;
                                    }
                                    element.appendChild(imageElement);

                                    if (data.node.data.device_iodef[0].txt !== "") {
                                        var para = document.createElement("span"); 
                                        para.innerText = " " + data.node.data.device_iodef[0].txt;
                                        para.setAttribute("style", "vertical-align: top;")
                                        element.appendChild(para); 
                                    }

                                    return element;
                                } else 
                                    return null;
                            }},
                            {headerName: t("maingrid.P7"), field: "p7", width: 100, minWidth: 80, maxWidth: 110, cellRenderer: (data) => {
                                if (data.node.data.device_iodef == null) 
                                    return null;
                                if (Object.keys(data.node.data.device_iodef[7]).length > 0) {
                                    var element = document.createElement("span");

                                    var imageElement = document.createElement("img");
                                    if (data.value === 0)
                                    {
                                        imageElement.src = data.node.data.device_iodef[7].iconon;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[7].coloron)); 
                                        imageElement.title = data.node.data.device_iodef[7].hinton;
                                    }
                                    else {
                                        imageElement.src = data.node.data.device_iodef[7].iconoff;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[7].coloroff));
                                        imageElement.title = data.node.data.device_iodef[7].hintoff;
                                    }
                                    element.appendChild(imageElement);

                                    if (data.node.data.device_iodef[7].txt !== "") {
                                        var para = document.createElement("span"); 
                                        para.innerText = " " + data.node.data.device_iodef[7].txt;
                                        para.setAttribute("style", "vertical-align: top;")
                                        element.appendChild(para); 
                                    }

                                    return element;
                                } else 
                                    return null;
                            }},
                            {headerName: t("maingrid.P8"), field: "p8", width: 130, minWidth: 100, maxWidth: 200, cellRenderer: (data) => {
                                var element = document.createElement("span");
                                if (data.node.data.device_iodef[8] !== undefined && Object.keys(data.node.data.device_iodef[8]).length > 0) {
                                    var imageElement = document.createElement("img");
                                    if (data.value === 0)
                                    {
                                        imageElement.src = data.node.data.device_iodef[8].iconon;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[8].coloron)); 
                                        imageElement.title = data.node.data.device_iodef[8].hinton;
                                    }
                                    else {
                                        imageElement.src = data.node.data.device_iodef[8].iconoff;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[8].coloroff));
                                        imageElement.title = data.node.data.device_iodef[8].hintoff;
                                    }
                                    element.appendChild(imageElement);
    
                                    if (data.node.data.device_iodef[8].txt !== "") {
                                        var para = document.createElement("span"); 
                                        para.innerText = " " + data.node.data.device_iodef[8].txt;
                                        para.setAttribute("style", "vertical-align: top;")
                                        element.appendChild(para); 
                                    }
                                } 
                                return element;
                            }},
                            {headerName: t("maingrid.P9"), field: "p9", width: 130, minWidth: 100, maxWidth: 200, cellRenderer: (data) => {
                                var element = document.createElement("span");
                                if (data.node.data.device_iodef[9] !== undefined && Object.keys(data.node.data.device_iodef[9]).length > 0) {
                                    var imageElement = document.createElement("img");
                                    if (data.value === 0)
                                    {
                                        imageElement.src = data.node.data.device_iodef[9].iconon;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[9].coloron)); 
                                        imageElement.title = data.node.data.device_iodef[9].hinton;
                                    }
                                    else {
                                        imageElement.src = data.node.data.device_iodef[9].iconoff;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[9].coloroff));
                                        imageElement.title = data.node.data.device_iodef[9].hintoff;
                                    }
                                    element.appendChild(imageElement);
    
                                    if (data.node.data.device_iodef[9].txt !== "") {
                                        var para = document.createElement("span"); 
                                        para.innerText = " " + data.node.data.device_iodef[9].txt;
                                        para.setAttribute("style", "vertical-align: top;")
                                        element.appendChild(para); 
                                    }
                                }
                                return element;
                            }},
                            {headerName: t("maingrid.P1"), field: "p1", width: 170, minWidth: 100, maxWidth: 200, cellRenderer: (data) => {
                                if (data.node.data.device_iodef == null) 
                                    return null;
                                if (Object.keys(data.node.data.device_iodef[1]).length > 0) {
                                    var element = document.createElement("span");

                                    var imageElement = document.createElement("img");
                                    if (data.value === 0)
                                    {
                                        imageElement.src = data.node.data.device_iodef[1].iconon;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[1].coloron)); 
                                        imageElement.title = data.node.data.device_iodef[1].hinton;
                                    }
                                    else {
                                        imageElement.src = data.node.data.device_iodef[1].iconoff;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[1].coloroff));
                                        imageElement.title = data.node.data.device_iodef[1].hintoff;
                                    }
                                    element.appendChild(imageElement);
    
                                    if (data.node.data.device_iodef[1].txt !== "") {
                                        var para = document.createElement("span"); 
                                        para.innerText = " " + data.node.data.device_iodef[1].txt;
                                        para.setAttribute("style", "vertical-align: top;")
                                        element.appendChild(para); 
                                    }
    
                                    return element;
                                } else
                                    return null;
                            }},
                            {headerName: t("maingrid.P2"), field: "p2", width: 170, minWidth: 80, maxWidth: 200, cellRenderer: (data) => {
                                if (data.node.data.device_iodef == null) 
                                    return null;
                                if (Object.keys(data.node.data.device_iodef[2]).length > 0) {
                                    var element = document.createElement("span");

                                    var imageElement = document.createElement("img");
                                    if (data.value === 0)
                                    {
                                        imageElement.src = data.node.data.device_iodef[2].iconon;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[2].coloron)); 
                                        imageElement.title = data.node.data.device_iodef[2].hinton;
                                    }
                                    else {
                                        imageElement.src = data.node.data.device_iodef[2].iconoff;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[2].coloroff));
                                        imageElement.title = data.node.data.device_iodef[2].hintoff;
                                    }
                                    element.appendChild(imageElement);
    
                                    if (data.node.data.device_iodef[2].txt !== "") {
                                        var para = document.createElement("span"); 
                                        para.innerText = " " + data.node.data.device_iodef[2].txt;
                                        para.setAttribute("style", "vertical-align: top;")
                                        element.appendChild(para); 
                                    }
    
                                    return element;
                                } else
                                    return null;
                            }},
                            {headerName: t("maingrid.P3"), field: "p3", width: 170, minWidth: 80, maxWidth: 200, cellRenderer: (data) => {
                                if (data.node.data.device_iodef == null) 
                                    return null;
                                if (Object.keys(data.node.data.device_iodef[3]).length > 0) {
                                    var element = document.createElement("span");

                                    var imageElement = document.createElement("img");
                                    if (data.value === 0)
                                    {
                                        imageElement.src = data.node.data.device_iodef[3].iconon;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[3].coloron)); 
                                        imageElement.title = data.node.data.device_iodef[3].hinton;
                                    }
                                    else {
                                        imageElement.src = data.node.data.device_iodef[3].iconoff;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[3].coloroff));
                                        imageElement.title = data.node.data.device_iodef[3].hintoff;
                                    }
                                    element.appendChild(imageElement);
    
                                    if (data.node.data.device_iodef[3].txt !== "") {
                                        var para = document.createElement("span"); 
                                        para.innerText = " " + data.node.data.device_iodef[3].txt;
                                        para.setAttribute("style", "vertical-align: top;")
                                        element.appendChild(para); 
                                    }
    
                                    return element;
                                } else
                                    return null;
                            }},
                            {headerName: t("maingrid.P4"), field: "p4", width: 170, minWidth: 80, maxWidth: 200, cellRenderer: (data) => {
                                if (data.node.data.device_iodef == null) 
                                    return null;
                                if (Object.keys(data.node.data.device_iodef[4]).length > 0) {
                                    var element = document.createElement("span");

                                    var imageElement = document.createElement("img");
                                    if (data.value === 0)
                                    {
                                        imageElement.src = data.node.data.device_iodef[4].iconon;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[4].coloron)); 
                                        imageElement.title = data.node.data.device_iodef[4].hinton;
                                    }
                                    else {
                                        imageElement.src = data.node.data.device_iodef[4].iconoff;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[4].coloroff));
                                        imageElement.title = data.node.data.device_iodef[4].hintoff;
                                    }
                                    element.appendChild(imageElement);
    
                                    if (data.node.data.device_iodef[1].txt !== "") {
                                        var para = document.createElement("span"); 
                                        para.innerText = " " + data.node.data.device_iodef[4].txt;
                                        para.setAttribute("style", "vertical-align: top;")
                                        element.appendChild(para); 
                                    }
    
                                    return element;
                                } else
                                    return null;
                            }},
                            {headerName: t("maingrid.P5"), field: "p5", width: 170, minWidth: 80, maxWidth: 200, cellRenderer: (data) => {
                                if (data.node.data.device_iodef == null) 
                                    return null;
                                if (Object.keys(data.node.data.device_iodef[5]).length > 0) {
                                    var element = document.createElement("span");

                                    var imageElement = document.createElement("img");
                                    if (data.value === 0)
                                    {
                                        imageElement.src = data.node.data.device_iodef[5].iconon;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[5].coloron)); 
                                        imageElement.title = data.node.data.device_iodef[5].hinton;
                                    }
                                    else {
                                        imageElement.src = data.node.data.device_iodef[5].iconoff;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[5].coloroff));
                                        imageElement.title = data.node.data.device_iodef[5].hintoff;
                                    }
                                    element.appendChild(imageElement);
    
                                    if (data.node.data.device_iodef[1].txt !== "") {
                                        var para = document.createElement("span"); 
                                        para.innerText = " " + data.node.data.device_iodef[5].txt;
                                        para.setAttribute("style", "vertical-align: top;")
                                        element.appendChild(para); 
                                    }
    
                                    return element;
                                } else
                                    return null;
                            }},
                            {headerName: t("maingrid.P6"), field: "p6", width: 170, minWidth: 80, maxWidth: 200, cellRenderer: (data) => {
                                if (data.node.data.device_iodef == null) 
                                    return null;
                                if (Object.keys(data.node.data.device_iodef[6]).length > 0) {
                                    var element = document.createElement("span");

                                    var imageElement = document.createElement("img");
                                    if (data.value === 0)
                                    {
                                        imageElement.src = data.node.data.device_iodef[6].iconon;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[6].coloron)); 
                                        imageElement.title = data.node.data.device_iodef[6].hinton;
                                    }
                                    else {
                                        imageElement.src = data.node.data.device_iodef[6].iconoff;
                                        imageElement.setAttribute("style", FilterColors(data.node.data.device_iodef[6].coloroff));
                                        imageElement.title = data.node.data.device_iodef[6].hintoff;
                                    }
                                    element.appendChild(imageElement);
    
                                    if (data.node.data.device_iodef[6].txt !== "") {
                                        var para = document.createElement("span"); 
                                        para.innerText = " " + data.node.data.device_iodef[6].txt;
                                        para.setAttribute("style", "vertical-align: top;")
                                        element.appendChild(para); 
                                    }
    
                                    return element;
                                } else
                                    return null;
                            }},
                            { headerName: "NetId", field: "device_netid", width: 280, minWidth: 80, maxWidth: 400, editable: true },
                        ]}

                rowData={this.Rows}
                modules={AllCommunityModules}
                getRowNodeId={this.getRowNodeId}
                onRowSelected={this.onRowSelected}
                onGridReady={this.onGridReady}
                >
            </AgGridReact>
        </div>
        );
    }
}

export default withRouter(withTranslation()(PhGrid));
