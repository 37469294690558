import React, { useContext, useEffect, useState  } from "react";
import { UserContext } from "../store/UserContext";
import agroApi from "../services/agroApi";


export default function Dashboard() {

   const { User } = useContext(UserContext);
   const [dashboardInfo, setDashboardInfo] = useState('')

   // load powerbi link from API
   useEffect(() => {
         agroApi.get("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/pbilnk", {
               headers: { token: User.token },
            })
            .then(response => {
               setDashboardInfo(response.data.customer_dblnk);
            })
            .catch(error => {
               console.error(error);
            });
   }, [User.ActiveCustomer, User.customers, User.token]);

   return (
      <div style={{ height: "90vh", width: "100hv"}}>
         <br />
         <iframe
            title="POWERBI"
            width="100%"
            height="100%"
            src={dashboardInfo}
            frameBorder="0"
            allowFullScreen={false}
         ></iframe>
      </div>
   );
}
