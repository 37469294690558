import React, { useState, useEffect } from 'react';
import Routes from './routes';
import './app.css';
import { UserContext } from './store/UserContext';


function App() {

  const [User, setUser] = useState(null);//useContext( UserContext );

  useEffect(() => {
    if (User)
      localStorage.setItem("agrotoken", JSON.stringify(User));
  }, [User]);

  if (!User)
  {
    const data = localStorage.getItem("agrotoken");
    if (data)
      setUser(JSON.parse(data));
  }

  return (
    <div className="App">
      <UserContext.Provider value={ { User, setUser } }>
        <Routes />
      </UserContext.Provider>
    </div>
  );
}

export default App;
