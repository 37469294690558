import React, { Suspense, useContext } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { UserContext } from './store/UserContext';

import Main from './pages/Main';
import Login from './pages/Login';

function PrivateRoute ({component: Component, ...rest})  {
    const { User } = useContext(UserContext);

    return (
    <Route
        { ... rest }
        render = {props =>
            User ? (
                <Component { ... props} />
            ) : (
                <Redirect to={{ pathname: "/", state: { from: props.location }}} />
            )
        }
    />
    )};

export default function Routes ({ history }) {

    return (
        <Suspense fallback="Loading">
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Login} />  
                <PrivateRoute path="/main" exact component={Main} />  
            </Switch>
        </BrowserRouter>
        </Suspense>
    );
}