import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import GridTable from "./GridTable";
import PivotGrid from "./PivotGrid";
import PhGrid from "./PhGrid";
import UtilityGrid from "./UtilityGrid"
import GranjeGrid from "./GranjeGrid";

import { useTranslation } from "react-i18next";
import { UserContext } from "../store/UserContext";

function TabPanel(props) {
   const { children, value, index } = props;
   return <div>{value === index && <Box p={0}>{children}</Box>}</div>;
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.any.isRequired,
   value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
   },
   container: {
      maxHeight: 350,
      marginLeft: 0,
   },
}));

//

export default function MainGrid() {
   const classes = useStyles();
   const [value, setValue] = React.useState(0);
   const { User } = useContext(UserContext);
   const { t } = useTranslation();
   var idx = 0;
   const mgs = User.customers[User.ActiveCustomer].maingrid_elements;

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   return (
      <div className={classes.root}>
         <AppBar position="static" color="default">
            <Tabs
               value={value}
               onChange={handleChange}
               indicatorColor="primary"
               textColor="primary"
               variant="fullWidth"
            >
               {mgs.indexOf("A") > -1 && <Tab label={t("maingrid.machinery")} />}
               {mgs.indexOf("B") > -1 && <Tab label={t("maingrid.waterpump")} />}
               {mgs.indexOf("C") > -1 && <Tab label={t("maingrid.pivots")} />}
               {mgs.indexOf("D") > -1 && <Tab label={t("maingrid.utility")} />}
               {mgs.indexOf("E") > -1 && <Tab label={t("maingrid.granje")} />}
            </Tabs>
         </AppBar>
         {mgs.indexOf("A") > -1 &&
            <TabPanel value={value} index={idx++}>
               <GridTable />
            </TabPanel>
         }
         {mgs.indexOf("B") > -1 &&
            <TabPanel value={value} index={idx++}>
               <PhGrid />
            </TabPanel>
         }
         {mgs.indexOf("C") > -1 &&
            <TabPanel value={value} index={idx++}>
               <PivotGrid />
            </TabPanel>
         }
         {mgs.indexOf("D") > -1 &&
            <TabPanel value={value} index={idx++}>
               <UtilityGrid />
            </TabPanel>
         }
         {mgs.indexOf("E") > -1 &&
            <TabPanel value={value} index={idx++}>
               <GranjeGrid />
            </TabPanel>
         }
      </div>
   );
}
