import { createTheme } from "@material-ui/core/styles";
//import { orange } from "@material-ui/core/colors";

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: "#e05219",
    },
    secondary: {
      main: "#b0bec5",
    },
  },
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiDrawer: {
      paper: {
        minWidth: 5,
      },
    },
  },
});

export default theme;
